<template>
    <vx-card title="APPROVAL INTER STORAGE">
        <div>
            <vs-tabs v-if="render" v-model="$route.params.tab_order">
                <vs-tab label="Waiting Approval">
                    <waiting></waiting>
                </vs-tab>
                <vs-tab label="Inquiry" id="inquiry">
                    <inquiry></inquiry>
                </vs-tab>
                <vs-tab label="Approved" id="approved">
                    <approved></approved>
                </vs-tab>
                <vs-tab label="Rejected">
                    <rejected></rejected>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import waiting from "./waiting"
import inquiry from "./inquiry"
import approved from "./approved"
import rejected from "./rejected"
export default {
    components: {waiting, inquiry, approved, rejected},
    data() {
        return {
            render: true,
        };
    },
    mounted() {},
}
</script>